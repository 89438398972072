import { assert, hasValue } from 'ajax-form-submit/js-utils'
import {
  elementIs,
  hasClass,
  addClass,
  removeClass,
  querySelector,
  registerEvent,
  triggerEvent,
  registerMutationObserver
} from 'ajax-form-submit/js-dom-utils'

import { createDatasetHelper } from 'ajax-form-submit/js-dataset-helper'


const CLASS_NAME = 'category-select'
const SOURCE_CLASS_NAME = `${CLASS_NAME}-source`
const SOURCE_SECOND_CLASS_NAME = `${CLASS_NAME}-source-second`
const SOURCE_THIRD_CLASS_NAME = `${CLASS_NAME}-source-third`
const PREFIX = 'cs'

class CategorySelect {

  constructor(el, opt = {}) {
    assert(elementIs(el, 'select'), 1, 'Select')
    this._root = el
    this._datasetHelper = createDatasetHelper(PREFIX)
    this._initRoot()
    this._initChild()
    this._initForm()
    this._initSource()
    this.select(this._root.value)
  }

  _initRoot() {
    registerEvent(this._root, 'change', event => {
      const value = event.target.value
      this.select(value)
      if (value === '') {
        triggerEvent(this._forms, 'ajax-form-submit-event-trigger', {
          input: {categoryId: ''}
        })
      } else {
        this._childs.forEach(child => {
          triggerEvent(child, 'change')
        })
      }
    })
  }

  _initChild() {
    const selector = this._datasetHelper.getValue(this._root, 'child')
    this._childs = querySelector(selector)
  }

  _initForm() {
    const selector = this._datasetHelper.getValue(this._root, 'form')
    this._forms = querySelector(selector)
  }

  _initSource() {
    this._source = {}
    this._selectedId = ''
    querySelector(`.${SOURCE_CLASS_NAME}`).forEach(sourceEl => {
      const rootButton = querySelector('button', sourceEl)[0]
      const rootCategory = {
        categoryId: rootButton.dataset.csId,
        categoryName: rootButton.dataset.csName,
        categories: []
      }

      const second = sourceEl.querySelector('div')
      querySelector(`.${SOURCE_SECOND_CLASS_NAME}`, second).forEach(secondEl => {
        const secondButton = querySelector('button', secondEl)[0]
        const secondCategory = {
          categoryId: secondButton.dataset.csId,
          categoryName: secondButton.dataset.csName,
          categories: []
        }
        this._source[secondCategory.categoryId] = secondCategory

        querySelector(`.${SOURCE_THIRD_CLASS_NAME}`, secondEl).forEach(thirdEl => {
          const thirdButton = thirdEl.querySelector('input')
          if (thirdButton.checked)
            this._selectedId = thirdButton.dataset.csId
          const thirdCategory = {
            categoryId: thirdButton.dataset.csId,
            categoryName: thirdButton.dataset.csName,
            count: thirdButton.dataset.csCount,
            categories: []
          }
          this._source[secondCategory.categoryId].categories.push(thirdCategory)

        })
      })
    })
  }

  select(value) {
    this._childs.forEach(child => child.innerHTML = '')
    if (value === '') {
      this._childs.forEach(child => addClass(child, 'hidden'))
    } else {
      const categories = this._source[value].categories
      this._childs.forEach(child => {
        removeClass(child, 'hidden')
        categories.forEach(category => {
          const option = document.createElement('option')
          option.value = category.categoryId
          option.textContent = `${category.categoryName} (${category.count})`
          if (this._selectedId == category.categoryId)
            option.setAttribute('selected', true)
          child.append(option)
        })
      })
    }

  }
}

window.addEventListener('DOMContentLoaded', event => {
  registerMutationObserver(el => {
    if (hasClass(el, CLASS_NAME))
      new CategorySelect(el)
  })

  querySelector(`.${CLASS_NAME}`).forEach(el => new CategorySelect(el))
}, { once: true })
